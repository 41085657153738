import React from 'react';
import { Layout, SEO } from '@componentsShared';
import { Wrapper } from '../../shared/components/atoms/containerGlobal/styled';
import { graphql, useStaticQuery } from 'gatsby';
import { getMetadataPage } from '@utils/metadata';
import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';

const ContainerResultados = styled.div`
    position: relative;
    width: 98%;
    max-width: 400px;
    margin: 45px auto;
    border: 1px solid #0df96c;
    padding: 0;
    background-color: #1d1828;
    border-radius: 16px;

    #ifr_resultados {
        width: 100%;
        min-height: 40rem;
        border-radius: 16px;
    }

    ${breakpoint('sm')`
        width: 90%;
        max-width: 1100px;
        padding: 65px 40px;

        #ifr_resultados {
            min-height: 65rem;
        }
    `}

    ${breakpoint('md')`
        #ifr_resultados {
            min-height: 100rem;
        }
    `}

    ${breakpoint('lg')`
        #ifr_resultados {
            min-height: 115rem;
        }
    `}
`;


const ResultadosPage = () => {

    const queryPageListaResultados = useStaticQuery(graphql`
        query pageListaResultados {
            allContentfulPaginaInternaFooter(filter: { page: { eq: "ListaResultados" } }) {
                nodes {
                    title
                    slug
                    metaData {
                        title
                        descripcion
                        keyWords
                        image {
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    const {
        nodes: [page]
    } = queryPageListaResultados.allContentfulPaginaInternaFooter;


    return (
        <Layout>
            <SEO
                {...getMetadataPage(page.metaData, page.slug || '/lista-resultados/')}
            />
            <Wrapper>
                <ContainerResultados>
                    <iframe
                        id="ifr_resultados"
                        src="https://boletin.gana.com.co/"
                        title="resultados"
                    ></iframe>
                </ContainerResultados>
            </Wrapper>
        </Layout>
    );
};

export default ResultadosPage;
